<template>
  <div class="notifications-container flex-col">
    <div v-for="(componentObject, index) in notificationsQueue" :key="index">
      <LazyNotificationCard v-if="componentObject.name.text">
        <div v-html="componentObject.name.text" />
      </LazyNotificationCard>
      <component v-else :key="componentObject.id" :is="componentObject.name" :id="componentObject.id" />
    </div>
  </div>
</template>

<script>
import { useNotificationStore } from '../../stores/notification'

export default {
  computed: {
    notificationsQueue() {
      return useNotificationStore().queue
    },
  },
  methods: {
    cardId(index) {
      return 'notification-card-' + index
    },
  },
}
</script>

<style lang="less" scoped>
.notifications-container {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 90;

  flex-direction: column-reverse;
}

@media @bp-desktop {
  .notifications-container {
    top: 100px;
    right: 40px;
  }
}
</style>
